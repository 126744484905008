import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/images/home_a.png';
import _imports_1 from '@/assets/images/home.png';
import _imports_2 from '@/assets/images/liquidity_a.png';
import _imports_3 from '@/assets/images/liquidity.png';
import _imports_4 from '@/assets/images/new_a.png';
import _imports_5 from '@/assets/images/new.png';
import _imports_6 from '@/assets/images/me_a.png';
import _imports_7 from '@/assets/images/me.png';
const _hoisted_1 = {
  class: "isTabbar"
};
const _hoisted_2 = {
  class: "contents acea-row row-between-wrapper"
};
const _hoisted_3 = {
  key: 0,
  src: _imports_0
};
const _hoisted_4 = {
  key: 1,
  src: _imports_1
};
const _hoisted_5 = {
  key: 0,
  src: _imports_2
};
const _hoisted_6 = {
  key: 1,
  src: _imports_3
};
const _hoisted_7 = {
  key: 0,
  src: _imports_4
};
const _hoisted_8 = {
  key: 1,
  src: _imports_5
};
const _hoisted_9 = {
  key: 0,
  src: _imports_6
};
const _hoisted_10 = {
  key: 1,
  src: _imports_7
};
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { router } from "@/router";
export default {
  __name: 'Tabbar',
  emits: ['success'],
  setup(__props, {
    emit: __emit
  }) {
    const route = useRoute();
    const bgColors = ref(null);
    let toWidth = ref(0);
    const activeId = route.query.id;
    const active = ref(0);
    const emit = __emit;
    onMounted(() => {
      if (bgColors.value) {
        toWidth = bgColors.value.offsetWidth;
      }
      let url = `/home?id=${activeId}`;
      openUrl(url, parseFloat(activeId));
    });
    const openUrl = (url, index) => {
      router.push(url);
      emit('success', index);
      active.value = index;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        style: {
          "height": "75px"
        }
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass(["notsBg", {
          'noleft': active.value === 3
        }]),
        style: _normalizeStyle(`transform:translateX(${_unref(toWidth) * active.value}px)`)
      }, null, 6), _createElementVNode("div", {
        ref_key: "bgColors",
        ref: bgColors,
        onClick: _cache[0] || (_cache[0] = $event => openUrl('/home?id=0', 0))
      }, [active.value === 0 ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))], 512), _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => openUrl('/home?id=1', 1))
      }, [active.value === 1 ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : (_openBlock(), _createElementBlock("img", _hoisted_6))]), _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = $event => openUrl('/home?id=2', 2))
      }, [active.value === 2 ? (_openBlock(), _createElementBlock("img", _hoisted_7)) : (_openBlock(), _createElementBlock("img", _hoisted_8))]), _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = $event => openUrl('/home?id=3', 3))
      }, [active.value === 3 ? (_openBlock(), _createElementBlock("img", _hoisted_9)) : (_openBlock(), _createElementBlock("img", _hoisted_10))])])])], 64);
    };
  }
};